
import { Component, Mixins } from 'vue-property-decorator'
import VerificationTokenCreateDto from 'tradingmate-modules/src/models/api/signup/VerificationTokenCreateDto'
import VerificationTokenType from 'tradingmate-modules/src/models/api/signup/VerificationTokenType'
import { Services } from 'tradingmate-modules/src/services'
import VerificationTokenReadDto from 'tradingmate-modules/src/models/api/signup/VerificationTokenReadDto'
import PasswordResetRequest from 'tradingmate-modules/src/models/api/signup/PasswordResetRequest'
import PasswordResetResponse from 'tradingmate-modules/src/models/api/signup/PasswordResetResponse'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'
import Alert from '@/components/status/Alert.vue'
import AuthPageContent from '@/components/collections/account/AuthPageContent.vue'
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'

enum PasswordResetReason {
  NeedsPasswordReset = 'npr'
}

@Component({
  components: {
    AuthPageContent,
    Alert,
    Form,
    FormField,
    InputText
  }
})

export default class ResetPassword extends Mixins(FormPage) {
  private passwordResetReason = ''

  private verificationTokenCreateDto: VerificationTokenCreateDto = { Type: VerificationTokenType.PasswordReset, Email: '' }
  private verificationToken: VerificationTokenReadDto | null = null
  // private verificationTokenCall = new ApiCall(Services.API.Signup.RequestVerificationCode)

  private passwordResetRequest: PasswordResetRequest = { Email: '', VerificationToken: '', Password: '', PasswordConfirm: '' }
  private passwordResetResponse: PasswordResetResponse | null = null
  // private passwordResetCall = new ApiCall(Services.API.Signup.ResetPassword)

  mounted (): void {
    const prrQuery = this.$route.query.prr?.toString() ?? null
    switch (prrQuery) {
      case PasswordResetReason.NeedsPasswordReset:
        this.passwordResetReason = PasswordResetReason.NeedsPasswordReset.toString()
        break
      default:
        break
    }
  }

  isRequestingVerificaitonCode = false
  requestVerificationCode (): void {
    this.isRequestingVerificaitonCode = true
    this.httpError = null
    Services.API.Signup.RequestVerificationCode(this.verificationTokenCreateDto)
      .then((res) => {
        this.verificationToken = res
      }).catch((error) => {
        console.log(error)
        this.httpError = error
      }).finally(() => {
        this.isRequestingVerificaitonCode = false
      })

    // this.verificationToken = this.verificationTokenCall.Call(this.verificationTokenCreateDto)
  }

  isRequestPasswordReset = false
  requestPasswordReset (): void {
    this.passwordResetRequest.Email = this.verificationTokenCreateDto.Email
    this.isRequestPasswordReset = true
    this.httpError = null
    Services.API.Signup.ResetPassword(this.passwordResetRequest)
      .then((res) => {
        this.passwordResetResponse = res
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.isRequestPasswordReset = false
      })
    // this.passwordResetResponse = this.passwordResetCall.Call(this.passwordResetRequest)
  }
}

